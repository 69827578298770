<template>
  <header class="product-header m-b-20">
    <div class="ui container">
      <RoundImage class="ui m-r-15" :url="logoFile" v-if="logoAssetFolderFile" :backgroundColor="logoBgColor" />
      <div class="header-info">
        <h2 class="title m-b-10">{{ title }}</h2>
        <h3 class="h6 m-b-5" v-if="description">{{ description }}</h3>
      </div>
    </div>
  </header>
</template>

<script>
import RoundImage from '@/components/RoundImage'

export default {
  name: 'HeaderSavingsDetails',
  components: {
    RoundImage
  },
  props: {
    logoAssetFolderFile: {
      type: String
    },
    title: {
      required: true,
      type: String
    },
    description: {
      type: String
    },
    logoBgColor: {
      type: String
    }
  },
  computed: {
    logoFile() {
      return `/img/savings/${this.logoAssetFolderFile}`
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../assets/styles/swoop/variables';

.title {
  color: $color-primary;
}
</style>
