<template>
  <div class="utilities-page">
    <!-- HEADER -->
    <HeaderSavingsDetails title="Swoop Energy" description="Utilities" logoAssetFolderFile="swoop-logo-blue.svg" />

    <aside class="ui stackable container">
      <div class="product-details-nav m-b-25 p-t-20">
        <BackBtn />
        <NeedHelp />
      </div>

      <!-- When Loading -->
      <template v-if="currentStage === null">
        <div class="opportunity-details-action card m-b-50">
          <div class="ui active inverted dimmer page-loader">
            <div class="ui medium text loader"><spinner :size="64" :loader="true" color />t</div>
          </div>
        </div>
      </template>

      <!-- Start Screen -->
      <template v-if="opportunityStages.isStartApp">
        <div class="opportunity-details card m-b-50">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="box">
                <div class="logo-container">
                  <SwoopLogo width="245px"/>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 ui flex column center action-container-wrapper">
              <div class="box action-container">
                <button class="btn btn-sm btn-primary btn-product-action m-b-10" type="button" v-promise-btn @click="startApplicationClickHandler">
                  Start application process
                </button>
              </div>
              <div class="box action-container">
                <button class="btn btn-sm btn-product-action btn-secondary" type="button" v-promise-btn @click="requestCallbackClickHandler">Request Callback</button>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- When Callback requested -->
      <template v-if="opportunityStages.isRequestApp">
        <div class="opportunity-details-action card m-b-50">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="box">
                <h3 class="h3 ui text dark">You have requested a callback</h3>
                <p>An email has been sent to confirm that you have requested a callback for more information.</p>
                <p>Our team is a busy bunch so please give us 2 working days to review and feel free to contact our friendly team anytime.</p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="application-logo">
                <img class src="@/assets/images/request-callback-illustration.svg" alt="request callback" />
              </div>
              <div class="ui flex align center">
                <router-link class="btn btn-sm btn-primary" :to="{ name: 'savings-services' }">view services</router-link>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Equity Start Screen -->
      <template v-if="opportunityStages.isUtilityBill">
        <div class="opportunity-details-action card m-b-50">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="box">
                <h3 class="h3 ui text dark">Utility bill</h3>
                <p>
                  Before you can proceed please make sure that you have uploaded your latest utility bill for the <tenant-text name="projectName" /> team to review.
                </p>
                <p>Once you have uploaded these opposite, you can find them in your Data Room</p>
                <button class="btn btn-sm btn-primary p-l-65 p-r-65" type="button" v-promise-btn @click="submitForSwoopReviewClickHandler" :disabled="!hasAllRequiredDocuments">
                  <tenant-text name="submitForSwoopReview" />
                </button>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-list">
              <div class="box">
                <ProductRequiredDocuments class="profile" v-bind="{ documentsList, requiredDocumentsList }" @change="documentsListChangeHandler" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- When Application created and it is under review -->
      <template v-if="opportunityStages.isUnderReview">
        <div class="opportunity-details-action card m-b-50">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="box">
                <h3 class="h3 ui text dark">Your application is under review!</h3>
                <p>An email has been sent to confirm that your application has been sent and is now with the <tenant-text name="projectName" /> team for review.</p>
                <p>Our team is a busy bunch, so please give us 2 working days to review your application and feel free to contact our friendly team anytime.</p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="application-logo">
                <img class src="@/assets/images/savings.svg" alt="application under review" width="154" height="132.34" />
              </div>
              <div class="ui flex align center p-t-20">
                <router-link class="btn btn-sm btn-primary" :to="{ name: 'savings-services' }">view saving options</router-link>
              </div>
            </div>
          </div>
        </div>
      </template>
    </aside>

    <main class="opportunity-details-info">
      <div class="ui stackable container">
        <h3 class="title bb">About Swoop Energy</h3>
        <div class="container-footer">
          <p>Swoop Energy is changing the way businesses procure their energy. Using our simple and effective platform we can quickly help reduce your energy bill.</p>
          <p>
            We will get you the best value for your energy contracts, reducing your energy consumption, minimising carbon footprint and ensuring that your energy bills are correct
            for your usage.
          </p>
          <p>
            Businesses of all sizes from all over Australia rely on our energy services, whether that be one site or multi-sites across the country. We remove the stress of dealing
            with the energy contracts by contacting your existing and new supplier, as well as managing the process throughout, from start to finish.
          </p>
          <p>
            The Swoop Energy Process: By providing us with a copy of your utility bill, we will come back to you with a quote for your business. Simply upload your bill to get
            started.
          </p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { bankGetters } from '@/store/modules/bank/routines'
import { postCompanyRequestSavingsCallback, getCompanyUtilitiesStatus, submitCompanyUtilities } from '@/api/company'
import { getDocumentsList } from '@/api/document'
import { toastDefaultOptions } from '@/config/vue-toast'
import BackBtn from '@/components/BackBtn'
import NeedHelp from '@/components/NeedHelp'
import Spinner from '@/components/Spinner'
import ProductRequiredDocuments from '@/components/ProductRequiredDocuments'
import HeaderSavingsDetails from '@/features/savings/components/HeaderSavingsDetails'
import SwoopLogo from '@/components/SwoopLogo'

export default {
  name: 'Utilities',
  components: {
    NeedHelp,
    BackBtn,
    ProductRequiredDocuments,
    HeaderSavingsDetails,
    Spinner,
    SwoopLogo
  },
  data() {
    return {
      categoryName: 'Utilities',
      opportunityStages: {
        isStartApp: false,
        isRequestApp: false,
        isUtilityBill: false,
        isUnderReview: false
      },
      isUtilityUnderReview: null,
      documentsList: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      bankMandateId: bankGetters.BANK_MANDATE_ID
    }),
    currentStage() {
      const stages = Object.entries(this.opportunityStages)
      const [currentStageName = null] = stages.find(([stageName, isActive]) => isActive) || []
      return currentStageName
    },
    requiredDocumentsList() {
      if (!this.documentsList) return []
      // const { documentsList } = this
      return [
        {
          isDocumentUploaded: this.hasUploadedUtilityBill,
          key: 'hasUtilityBill',
          label: 'Utility Bill',
          title: 'Utility Bill',
          type: 'Other'
        }
      ]
    },
    hasUploadedUtilityBill() {
      if (!this.documentsList) return false
      return !!this.documentsList.find(doc => doc.type === 'Other' && doc.title === 'Utility Bill')
    },
    hasAllRequiredDocuments() {
      return this.hasUploadedUtilityBill
    }
  },
  methods: {
    setOpportunityStage(nextStage) {
      if (this.currentStage && this.currentStage in this.opportunityStages) {
        this.opportunityStages[this.currentStage] = false
      }
      this.opportunityStages[nextStage] = true
    },
    requestCallbackClickHandler() {
      const { companyId, categoryName } = this
      return postCompanyRequestSavingsCallback({ companyId, categoryName })
        .then(res => {
          this.setOpportunityStage('isRequestApp')
        })
        .catch(e => this.toastError)
    },
    startApplicationClickHandler() {
      return this.loadDocumentsList()
        .then(() => {
          this.setOpportunityStage('isUtilityBill')
        })
        .catch(e => this.toastError)
    },
    submitForSwoopReviewClickHandler() {
      return submitCompanyUtilities(this.companyId)
        .then(() => {
          this.setOpportunityStage('isUnderReview')
        })
        .catch(e => this.toastError)
    },
    loadCompanyUtilitiesStatus() {
      return getCompanyUtilitiesStatus(this.companyId)
        .then(res => {
          this.isUtilityUnderReview = res.data.isSubmited
        })
        .catch(e => {
          this.isUtilityUnderReview = null
        })
    },
    loadDocumentsList() {
      return getDocumentsList(this.companyId)
        .then(res => {
          this.documentsList = res.data
        })
        .catch(e => {
          this.documentsList = []
        })
    },
    documentsListChangeHandler() {
      return this.loadDocumentsList()
    },
    toastError() {
      this.$toasted.clear()
      this.$toasted.show('Something went wrong. Please try again later', { ...toastDefaultOptions, type: 'error', icon: 'error' })
    },
    async init() {
      await this.loadCompanyUtilitiesStatus()
      if (this.isUtilityUnderReview) {
        this.setOpportunityStage('isUnderReview')
      } else {
        this.setOpportunityStage('isStartApp')
      }
    }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="scss" src="@/components/OpportunityView.scss" scoped></style>
<style lang="scss" scoped>
@import '../../../../../../src/assets/styles/swoop/variables';

.logo-container {
  min-height: 204px;
  border: 1px solid var(--color-primary-200);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $breakpoint-md-max) {
    margin: 1rem;
  }
}
.action-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
